import { render, staticRenderFns } from "./Threads.vue?vue&type=template&id=7e64a268&scoped=true&"
import script from "./Threads.vue?vue&type=script&lang=ts&"
export * from "./Threads.vue?vue&type=script&lang=ts&"
import style0 from "./Threads.vue?vue&type=style&index=0&id=7e64a268&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e64a268",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroUserNoImage: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/userNoImage.vue').default})
