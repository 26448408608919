import { render, staticRenderFns } from "./login.vue?vue&type=template&id=454fb2c2&scoped=true&"
import script from "./login.vue?vue&type=script&lang=ts&"
export * from "./login.vue?vue&type=script&lang=ts&"
import style0 from "./login.vue?vue&type=style&index=0&id=454fb2c2&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "454fb2c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormLogin: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/Login.vue').default})
