import { render, staticRenderFns } from "./contrasena-actualizada-exitosamente.vue?vue&type=template&id=17a0f94b&scoped=true&"
import script from "./contrasena-actualizada-exitosamente.vue?vue&type=script&lang=ts&"
export * from "./contrasena-actualizada-exitosamente.vue?vue&type=script&lang=ts&"
import style0 from "./contrasena-actualizada-exitosamente.vue?vue&type=style&index=0&id=17a0f94b&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a0f94b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnGeneric: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/Generic.vue').default})
