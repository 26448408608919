import { render, staticRenderFns } from "./crear.vue?vue&type=template&id=5205f00e&"
import script from "./crear.vue?vue&type=script&lang=ts&"
export * from "./crear.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardShareStepTwo: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/share/StepTwo.vue').default})
