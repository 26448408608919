import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5b581518&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=5b581518&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b581518",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroBreadcrumbs: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Breadcrumbs.vue').default,CardMainGlobalFeatured: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/main/GlobalFeatured.vue').default,MicroSliderItem: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/SliderItem.vue').default,MicroSlider: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Slider.vue').default,CardImagesGallery: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/ImagesGallery.vue').default,MicroTabs: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Tabs.vue').default,CardMainGlobal: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/main/Global.vue').default,MicroLazy: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Lazy.vue').default,BtnSeeMoreResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/SeeMoreResults.vue').default,SidebarDescription: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/sidebar/Description.vue').default,SidebarStores: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/sidebar/Stores.vue').default})
