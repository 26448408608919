import { render, staticRenderFns } from "./FeaturedStores.vue?vue&type=template&id=33b4c77a&scoped=true&"
import script from "./FeaturedStores.vue?vue&type=script&lang=ts&"
export * from "./FeaturedStores.vue?vue&type=script&lang=ts&"
import style0 from "./FeaturedStores.vue?vue&type=style&index=0&id=33b4c77a&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b4c77a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardFeaturedStore: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/FeaturedStore.vue').default,MicroSliderItem: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/SliderItem.vue').default,MicroSlider: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Slider.vue').default,MicroNoResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/NoResults.vue').default})
