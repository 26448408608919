import { render, staticRenderFns } from "./inicio-sin-contrasena.vue?vue&type=template&id=1d062b90&scoped=true&"
import script from "./inicio-sin-contrasena.vue?vue&type=script&lang=ts&"
export * from "./inicio-sin-contrasena.vue?vue&type=script&lang=ts&"
import style0 from "./inicio-sin-contrasena.vue?vue&type=style&index=0&id=1d062b90&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d062b90",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormEmailLoginStrategy: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/EmailLoginStrategy.vue').default})
