import { render, staticRenderFns } from "./StoresLazy.vue?vue&type=template&id=3ac146ce&"
import script from "./StoresLazy.vue?vue&type=script&lang=ts&"
export * from "./StoresLazy.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarStoresSkeleton: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/sidebar/StoresSkeleton.vue').default})
