import { render, staticRenderFns } from "./prensa.vue?vue&type=template&id=6a95be16&scoped=true&"
import script from "./prensa.vue?vue&type=script&lang=ts&"
export * from "./prensa.vue?vue&type=script&lang=ts&"
import style0 from "./prensa.vue?vue&type=style&index=0&id=6a95be16&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a95be16",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutInfoPages: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/layout/InfoPages.vue').default})
