import { render, staticRenderFns } from "./index.vue?vue&type=template&id=411c4bd2&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=411c4bd2&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "411c4bd2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroBreadcrumbs: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Breadcrumbs.vue').default,ChipCategory: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/chip/Category.vue').default,CardBlog: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/Blog.vue').default,BtnSeeMoreResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/SeeMoreResults.vue').default,MicroNoResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/NoResults.vue').default})
