import { render, staticRenderFns } from "./OfferSummaryLazy.vue?vue&type=template&id=7852a7f3&"
import script from "./OfferSummaryLazy.vue?vue&type=script&lang=ts&"
export * from "./OfferSummaryLazy.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarOfferSummarySkeleton: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/sidebar/OfferSummarySkeleton.vue').default,SidebarOfferSummary: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/sidebar/OfferSummary.vue').default})
