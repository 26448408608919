import { render, staticRenderFns } from "./CommentThread.vue?vue&type=template&id=2c8a5d46&scoped=true&"
import script from "./CommentThread.vue?vue&type=script&lang=ts&"
export * from "./CommentThread.vue?vue&type=script&lang=ts&"
import style0 from "./CommentThread.vue?vue&type=style&index=0&id=2c8a5d46&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c8a5d46",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardComment: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/Comment.vue').default})
