import { render, staticRenderFns } from "./ajustes.vue?vue&type=template&id=6b320340&scoped=true&"
import script from "./ajustes.vue?vue&type=script&lang=ts&"
export * from "./ajustes.vue?vue&type=script&lang=ts&"
import style0 from "./ajustes.vue?vue&type=style&index=0&id=6b320340&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b320340",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardDashboardRouter: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/dashboard/Router.vue').default,FormFieldAvatarSelect: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/AvatarSelect.vue').default,BtnGeneric: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/Generic.vue').default,FormFieldToggle: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/Toggle.vue').default})
