import { render, staticRenderFns } from "./olvide-mi-contrasena.vue?vue&type=template&id=41c2c6a0&scoped=true&"
import script from "./olvide-mi-contrasena.vue?vue&type=script&lang=ts&"
export * from "./olvide-mi-contrasena.vue?vue&type=script&lang=ts&"
import style0 from "./olvide-mi-contrasena.vue?vue&type=style&index=0&id=41c2c6a0&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41c2c6a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormForgotPassword: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/ForgotPassword.vue').default})
