import { render, staticRenderFns } from "./DiscountDetails.vue?vue&type=template&id=13264507&scoped=true&"
import script from "./DiscountDetails.vue?vue&type=script&lang=ts&"
export * from "./DiscountDetails.vue?vue&type=script&lang=ts&"
import style0 from "./DiscountDetails.vue?vue&type=style&index=0&id=13264507&prod&lang=postcss&scoped=true&"
import style1 from "./DiscountDetails.vue?vue&type=style&index=1&id=13264507&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13264507",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardAlertMessage: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/AlertMessage.vue').default,MicroBreadcrumbs: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Breadcrumbs.vue').default,DropdownOptions: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/dropdown/Options.vue').default,BtnDiscount: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/Discount.vue').default,MicroUserImage: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/userImage.vue').default,MicroBadge: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Badge.vue').default,CardMainGlobalFeatured: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/main/GlobalFeatured.vue').default,MicroSliderItem: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/SliderItem.vue').default,MicroSlider: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Slider.vue').default,MicroNoResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/NoResults.vue').default,MicroDiscountFooter: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/discountFooter.vue').default})
