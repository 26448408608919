import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=53bc1eea&"
import script from "./Login.vue?vue&type=script&lang=ts&"
export * from "./Login.vue?vue&type=script&lang=ts&"
import style0 from "./Login.vue?vue&type=style&index=0&id=53bc1eea&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnGeneric: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/Generic.vue').default,FormFieldGeneric: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/Generic.vue').default,FormFieldPassword: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/Password.vue').default,FormFieldCheckbox: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/Checkbox.vue').default,FormFieldAlert: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/Alert.vue').default})
