import { render, staticRenderFns } from "./Coupons.vue?vue&type=template&id=4db77448&scoped=true&"
import script from "./Coupons.vue?vue&type=script&lang=ts&"
export * from "./Coupons.vue?vue&type=script&lang=ts&"
import style0 from "./Coupons.vue?vue&type=style&index=0&id=4db77448&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db77448",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnCoupon: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/Coupon.vue').default})
