import { render, staticRenderFns } from "./descuentos.vue?vue&type=template&id=0b424888&scoped=true&"
import script from "./descuentos.vue?vue&type=script&lang=ts&"
export * from "./descuentos.vue?vue&type=script&lang=ts&"
import style0 from "./descuentos.vue?vue&type=style&index=0&id=0b424888&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b424888",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardDashboardRouter: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/dashboard/Router.vue').default,CardMainGlobal: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/main/Global.vue').default,MicroNoResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/NoResults.vue').default,BtnSeeMoreResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/SeeMoreResults.vue').default})
