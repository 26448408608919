import { render, staticRenderFns } from "./CategoryDetails.vue?vue&type=template&id=0d69b1b8&scoped=true&"
import script from "./CategoryDetails.vue?vue&type=script&lang=ts&"
export * from "./CategoryDetails.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryDetails.vue?vue&type=style&index=0&id=0d69b1b8&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d69b1b8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardAlertMessage: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/AlertMessage.vue').default,MicroBreadcrumbs: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Breadcrumbs.vue').default,CardMainGlobalFeatured: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/main/GlobalFeatured.vue').default,MicroSliderItem: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/SliderItem.vue').default,MicroSlider: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Slider.vue').default,CtaHandler: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/cta/Handler.vue').default,CardMainGlobal: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/main/Global.vue').default,MicroLazy: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Lazy.vue').default,BtnSeeMoreResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/SeeMoreResults.vue').default,MicroNoResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/NoResults.vue').default,SidebarDescription: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/sidebar/Description.vue').default,SidebarStoresLazy: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/sidebar/StoresLazy.vue').default,SidebarCategoriesTwoLazy: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/sidebar/CategoriesTwoLazy.vue').default})
