import { render, staticRenderFns } from "./_user.vue?vue&type=template&id=289d0c65&scoped=true&"
import script from "./_user.vue?vue&type=script&lang=ts&"
export * from "./_user.vue?vue&type=script&lang=ts&"
import style0 from "./_user.vue?vue&type=style&index=0&id=289d0c65&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289d0c65",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroBadge: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Badge.vue').default})
