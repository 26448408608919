import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=0c7ed47a&scoped=true&"
import script from "./Modal.vue?vue&type=script&lang=ts&"
export * from "./Modal.vue?vue&type=script&lang=ts&"
import style0 from "./Modal.vue?vue&type=style&index=0&id=0c7ed47a&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c7ed47a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutOverlay: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/layout/Overlay.vue').default})
