import { render, staticRenderFns } from "./actividad.vue?vue&type=template&id=150b8a6b&scoped=true&"
import script from "./actividad.vue?vue&type=script&lang=ts&"
export * from "./actividad.vue?vue&type=script&lang=ts&"
import style0 from "./actividad.vue?vue&type=style&index=0&id=150b8a6b&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150b8a6b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardDashboardRouter: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/dashboard/Router.vue').default,CardDashboardActivity: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/dashboard/Activity.vue').default,MicroNoResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/NoResults.vue').default,BtnSeeMoreResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/SeeMoreResults.vue').default})
