import { render, staticRenderFns } from "./ModalSecondary.vue?vue&type=template&id=0d3250b3&scoped=true&"
import script from "./ModalSecondary.vue?vue&type=script&lang=ts&"
export * from "./ModalSecondary.vue?vue&type=script&lang=ts&"
import style0 from "./ModalSecondary.vue?vue&type=style&index=0&id=0d3250b3&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d3250b3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalDashboardPassword: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/modal/dashboard/Password.vue').default,ModalDashboardEmail: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/modal/dashboard/Email.vue').default,ModalDashboardUsername: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/modal/dashboard/Username.vue').default,LayoutOverlay: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/layout/Overlay.vue').default})
