
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
type sitemapStore = {
    name: string
    slug: string
}
export default Vue.extend({
    name: 'SitemapStores',

    async asyncData({ $api }) {
        const sitemap = await $api.pages.loadSitemap('stores')

        return {
            sitemap,
        }
    },
    data() {
        return {
            sitemap: [] as sitemapStore[],
            metaInfo: {
                title: 'Mapa del sitio - MEGAdescuentos',
                description:
                    'Mapa del sitio de MEGAdescuentos. Consulta categorias, tiendas, cupones, ofertas y promociones de forma ordenada y accesible.',
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        letters(): { keys: string[]; lettersData: { [key: string]: sitemapStore[] } } {
            const lettersArray: { [key: string]: sitemapStore[] } = {
                '0-9': [],
                a: [],
                b: [],
                c: [],
                d: [],
                e: [],
                f: [],
                g: [],
                h: [],
                i: [],
                j: [],
                k: [],
                l: [],
                m: [],
                n: [],
                ñ: [],
                o: [],
                p: [],
                q: [],
                r: [],
                s: [],
                t: [],
                u: [],
                v: [],
                w: [],
                x: [],
                y: [],
                z: [],
            }

            const latinCharacters = 'ãàáäâèéëêìíïîòóöôùúüûÇç' /* Ññ */
            const asciiCharacters = 'aaaaaeeeeiiiioooouuuucc' /* nn */

            this.sitemap?.forEach((shop: sitemapStore) => {
                const letter = shop.name.trim().charAt(0).toLowerCase() as string

                if (!lettersArray[letter]) {
                    if (isNaN(Number(letter))) {
                        const indexOfLatinChar = latinCharacters.indexOf(letter)
                        lettersArray[asciiCharacters.charAt(indexOfLatinChar)].push(shop)
                    } else {
                        lettersArray['0-9'].push(shop)
                    }
                } else {
                    lettersArray[letter].push(shop)
                }
            })

            return {
                keys: Object.keys(lettersArray),
                lettersData: lettersArray,
            }
        },
    },
})
