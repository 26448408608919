import { render, staticRenderFns } from "./CategoriesList.vue?vue&type=template&id=424b534f&scoped=true&"
import script from "./CategoriesList.vue?vue&type=script&lang=ts&"
export * from "./CategoriesList.vue?vue&type=script&lang=ts&"
import style0 from "./CategoriesList.vue?vue&type=style&index=0&id=424b534f&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "424b534f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChipCategory: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/chip/Category.vue').default,FilterByLetter: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/filter/ByLetter.vue').default})
