import { render, staticRenderFns } from "./contacto.vue?vue&type=template&id=20692e5d&scoped=true&"
import script from "./contacto.vue?vue&type=script&lang=ts&"
export * from "./contacto.vue?vue&type=script&lang=ts&"
import style0 from "./contacto.vue?vue&type=style&index=0&id=20692e5d&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20692e5d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFieldGeneric: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/Generic.vue').default,DropdownShareSelect: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/dropdown/Share/Select.vue').default})
