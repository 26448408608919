import { render, staticRenderFns } from "./Price.vue?vue&type=template&id=535f40d2&scoped=true&"
import script from "./Price.vue?vue&type=script&lang=ts&"
export * from "./Price.vue?vue&type=script&lang=ts&"
import style0 from "./Price.vue?vue&type=style&index=0&id=535f40d2&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535f40d2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFieldCheckbox: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/Checkbox.vue').default})
