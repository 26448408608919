import { render, staticRenderFns } from "./index.vue?vue&type=template&id=255dfbfa&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=255dfbfa&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroBreadcrumbs: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Breadcrumbs.vue').default})
