import { render, staticRenderFns } from "./EmailLoginStrategy.vue?vue&type=template&id=6872347c&scoped=true&"
import script from "./EmailLoginStrategy.vue?vue&type=script&lang=ts&"
export * from "./EmailLoginStrategy.vue?vue&type=script&lang=ts&"
import style0 from "./EmailLoginStrategy.vue?vue&type=style&index=0&id=6872347c&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6872347c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFieldGeneric: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/Generic.vue').default,BtnGeneric: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/Generic.vue').default})
