import { render, staticRenderFns } from "./NewMessage.vue?vue&type=template&id=5ecd0e28&scoped=true&"
import script from "./NewMessage.vue?vue&type=script&lang=ts&"
export * from "./NewMessage.vue?vue&type=script&lang=ts&"
import style0 from "./NewMessage.vue?vue&type=style&index=0&id=5ecd0e28&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ecd0e28",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFieldGeneric: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/Generic.vue').default,MicroUserNoImage: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/userNoImage.vue').default,MicroBadge: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Badge.vue').default,FormFieldTextArea: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/form/field/TextArea.vue').default,BtnGeneric: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/Generic.vue').default})
