import { render, staticRenderFns } from "./gratis.vue?vue&type=template&id=1d1e9b16&scoped=true&"
import script from "./gratis.vue?vue&type=script&lang=ts&"
export * from "./gratis.vue?vue&type=script&lang=ts&"
import style0 from "./gratis.vue?vue&type=style&index=0&id=1d1e9b16&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d1e9b16",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroTabs: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Tabs.vue').default,CardMainGlobal: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/card/main/Global.vue').default,MicroLazy: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/micro/Lazy.vue').default,BtnSeeMoreResults: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/btn/SeeMoreResults.vue').default,SidebarStoresLazy: require('/home/cleavr/www.okdescontos.com.br/releases/20240911013415943/components/sidebar/StoresLazy.vue').default})
